import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import 'capacitor-secure-storage-plugin';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Smartlook, SmartlookSetupConfig } from '@ionic-native/smartlook/ngx';

const { Browser, App, SecureStoragePlugin, SplashScreen } = Plugins;

import {
  Capacitor,
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  AppUrlOpen } from '@capacitor/core';

const { PushNotifications, Device } = Plugins;

import { Platform } from '@ionic/angular';

import { skip } from 'rxjs/operators';

import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  cookieModal;
  private updateState = new BehaviorSubject(null);

  constructor(
    private platform: Platform,
    private router: Router,
    public modalController: ModalController,
  ) {
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });
  }

  ngOnInit() {
    this.initializeApp();
  }

}
