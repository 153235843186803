import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  hideBackButton = true;
  hideAccountButton = true;
  constructor(
  ) { }

  ngOnInit() {
  }

  async switchToPredictor() {
    if (window) {
      window.location.href = 'https://predictor.bet/#/';
    }
  }
}
