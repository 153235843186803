import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

const PAGES_COMPONENTS = [
  FooterComponent,
  HeaderComponent
];

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule, FontAwesomeModule],
  declarations: [PAGES_COMPONENTS],
  exports: [PAGES_COMPONENTS]
})
export class ComponentsModule {}
