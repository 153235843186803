import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AppVersion } from '@ionic-native/app-version/ngx';
import buildDate from './../../../environments/buildDate.json';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() dark: boolean;

  otaVersionNumber = '20211215a';
  buildDate: string;

  ionAppName: string;
  ionPackageName: string;
  ionVersionNumber: string;
  ionVersionCode: string|number;

  constructor(
    private router: Router,
    private appVersion: AppVersion,
    platform: Platform,
  ) {
    platform.ready().then(() => {
      if (platform.is('capacitor')) {
        this.appVersion.getVersionNumber().then(res => {
          this.ionVersionNumber = res + ' (' + this.otaVersionNumber + ')';
        });
      } else {
      this.ionVersionNumber = '';
      }
      this.ionVersionNumber += ' (' + this.otaVersionNumber + ')';
    });


    this.buildDate = moment(buildDate.date).format('YYYY-MM-DDTHH:mm');
  }

  openTermsAndConditions() {
    this.router.navigateByUrl('/termsandconditions');
  }

  openResponsibleGambling() {
    this.router.navigateByUrl('/responsible-gambling');
  }

  openGameRules() {
    this.router.navigateByUrl('/how-to-play');
  }

}
